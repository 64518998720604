var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tree-node",
      class: {
        "is-expand": _vm.expanded,
        "is-current":
          _vm.tree &&
          _vm.tree.currentNode &&
          _vm.tree.currentNode.teamId === _vm.node.teamId,
        "is-hide": !_vm.node.visible,
        "is-default": !_vm.node.checked,
        "is-checked": _vm.enableCheckedClass,
        "is-last-level": _vm.node.childTeams.length == 0,
        "is-indent": _vm.node.childTeams.length > 0 && _vm.node.level != 0,
        ["tree-node-level-" + _vm.node.level]: true,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.isLast ? _c("div", { staticClass: "white-line" }) : _vm._e(),
      _c(
        "div",
        {
          staticClass: "tree-node__content",
          style: {
            "padding-left":
              (_vm.node.level + _vm.tree.levelOffset) * _vm.tree.indent * 2 +
              "px",
            width: _vm.rowWidth + "px",
            "font-weight": _vm.node.level == 0 ? "bold" : "normal",
          },
        },
        [
          _c("span", {
            staticClass: "tree-node__expand-icon icon-caret-right",
            class: {
              "is-leaf": _vm.isLeaf,
              expand: !_vm.isLeaf && _vm.expanded,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleExpandIconClick.apply(null, arguments)
              },
            },
          }),
          _vm.node.checked
            ? _c("span", { staticClass: "tree-node__checked-pre-icon" })
            : _vm._e(),
          !_vm.hideCount && !_vm.isFlexOverFlow
            ? _c(
                "span",
                {
                  staticClass: "node-content",
                  style: { "max-width": _vm.rowWidth - 180 + "px" },
                  attrs: { title: _vm.node.teamName },
                },
                [_vm._v(_vm._s(_vm.node.teamName))]
              )
            : _vm._e(),
          !_vm.hideCount && !_vm.isFlexOverFlow
            ? _c("span", { staticClass: "node-content" }, [
                _vm._v("(" + _vm._s(_vm.node.membersCount) + ")"),
              ])
            : _vm._e(),
          !_vm.hideCount && _vm.isFlexOverFlow
            ? _c(
                "span",
                {
                  staticClass: "flex-overflow",
                  style: { width: _vm.rowWidth - 100 + "px" },
                  attrs: {
                    title:
                      _vm.node.teamName + "(" + _vm.node.membersCount + ")",
                  },
                },
                [
                  _c("i", { staticClass: "node-text" }, [
                    _vm._v(_vm._s(_vm.node.teamName)),
                  ]),
                  _c("i", { staticClass: "node-count" }, [
                    _vm._v("(" + _vm._s(_vm.node.membersCount) + ")"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.hideCount
            ? _c(
                "span",
                {
                  staticClass: "node-content",
                  style: { width: _vm.rowWidth - 100 + "px" },
                  attrs: { title: _vm.node.teamName },
                },
                [_vm._v(_vm._s(_vm.node.teamName))]
              )
            : _vm._e(),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasTick && _vm.enableCheckedClass,
                expression: "hasTick&&enableCheckedClass",
              },
            ],
            staticClass: "tree-node__checked-icon",
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded",
            },
          ],
          staticClass: "tree-node__children",
        },
        [
          _vm._l(_vm.node[this.tree.aliasChildNodes], function (child, index) {
            return [
              child.teamId != _vm.filterNode.teamId
                ? _c("tree-node", {
                    key: index,
                    attrs: {
                      node: child,
                      "node-list": _vm.node["childTeams"],
                      "current-index": index,
                      "row-width": _vm.rowWidth,
                      "filter-node": _vm.filterNode,
                      "hide-count": _vm.hideCount,
                      hasTick: _vm.hasTick,
                      isFlexOverFlow: _vm.isFlexOverFlow,
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }