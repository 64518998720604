<template>
    <div class="tree root-tree"
        :class="{
            'tree--highlight-current': highlightCurrent
        }"
    >
        <tree-node
            v-for="(child, index) in childNodes"
            :node="child"
            :node-list="childNodes"
            :currentIndex="index"
            :props="props"
            :row-width="rowWidth"
            :key="child.id || child.teamId"
            :current-node="currentNode"
            v-if="child.teamId != filterNode.teamId"
            :filter-node="filterNode"
            :hide-count="hideCount"
            :hasTick="hasTick"
            :isFlexOverFlow="isFlexOverFlow"
        ></tree-node>
        <div class="tree__empty-block" v-if="isEmpty">
            <span class="tree__empty-text">{{emptyText}}</span>
        </div>
    </div>
</template>

<script>
    import TreeNode from './tree-node.vue';

    export default {
        name: 'Tree',
        components: {
            TreeNode
        },
        props: {
            childNodes: {
                type: Array
            },
            emptyText: {
                type: String,
                default() {
                    return '暂无数据...'
                }
            },
            renderAfterExpand: {
                type: Boolean,
                default: true
            },
            nodeKey: String,
            checkStrictly: Boolean,
            defaultExpandAll: {
                type: Boolean,
                default: true
            },
            expandOnClickNode: {
                type: Boolean,
                default: false
            },
            autoExpandParent: {
                type: Boolean,
                default: true
            },
            defaultCheckedKeys: Array,
            defaultExpandKeys: Array,
            showCheckbox: {
                type: Boolean,
                default: false
            },
            props: {
                default() {
                    return {
                        children: 'children',
                        label: 'label',
                        icon: 'icon',
                        disabled: 'disabled'
                    }
                }
            },
            lazy: {
                type: Boolean,
                default: false
            },
            highlightCurrent: Boolean,
            filterNodeMethod: Function,
            indent: {
                type: Number,
                default: 10
            },
            aliasChildNodes: {
                type: String,
                required: true,
                default: 'childNodes'
            },
            selectSingle: {
                type: Boolean,
                default: true
            },
            rowWidth: {
                type: Number,
                default: 300
            },
            expandRootNode: {
                type: Boolean,
                default: false
            },
            autoCheck: {
                type: Boolean,
                default: false
            },
            filterNode: {
                type: Object,
                default() {
                    return {}
                }
            },
            hideCount: Boolean,
            hasTick:{
                type: Boolean,
                default: true
            },//是否需要勾号
            isFlexOverFlow:{
                type: Boolean,
                default: false
            }//是否自适应宽度超出隐藏
        },
        data() {
            return {
                currentNode: {},
                treeRoot: true,
                checkedNodes: [],
                rootNode: {}
            }
        },
        computed: {
            isEmpty() {
                return !this.childNodes || this.childNodes.length == 0;
            },
            levelOffset() {
                return -(this.childNodes && this.childNodes[0] && this.childNodes[0].level) || 0;
            }
        },
        watch: {
            currentNode(val) {
                let node = (val && val.hasOwnProperty('teamId')) ? val : this.childNodes[0];
                this.$emit('current-node-change', node)
            },
            childNodes(node) {
                if(node && node.length > 0) {
                    this.rootNode = node[0]
                }
            }
        },
        mounted() {

        }
    };
</script>

<style lang="scss">
    .tree__empty-block {
        height: 41px;
        line-height: 41px;
        background: transparent;
        padding-left: 20px;
    }
</style>
