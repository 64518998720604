var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tree root-tree",
      class: {
        "tree--highlight-current": _vm.highlightCurrent,
      },
    },
    [
      _vm._l(_vm.childNodes, function (child, index) {
        return child.teamId != _vm.filterNode.teamId
          ? _c("tree-node", {
              key: child.id || child.teamId,
              attrs: {
                node: child,
                "node-list": _vm.childNodes,
                currentIndex: index,
                props: _vm.props,
                "row-width": _vm.rowWidth,
                "current-node": _vm.currentNode,
                "filter-node": _vm.filterNode,
                "hide-count": _vm.hideCount,
                hasTick: _vm.hasTick,
                isFlexOverFlow: _vm.isFlexOverFlow,
              },
            })
          : _vm._e()
      }),
      _vm.isEmpty
        ? _c("div", { staticClass: "tree__empty-block" }, [
            _c("span", { staticClass: "tree__empty-text" }, [
              _vm._v(_vm._s(_vm.emptyText)),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }