<template>
    <div class="tree-node"
        @click.stop="handleClick"
        :class="{
             'is-expand': expanded,
             'is-current': tree && tree.currentNode && (tree.currentNode.teamId === node.teamId),
             'is-hide': !node.visible,
             'is-default': !node.checked,
             'is-checked': enableCheckedClass,
             'is-last-level': node.childTeams.length == 0,
             'is-indent': node.childTeams.length > 0 && node.level != 0,
            ['tree-node-level-' + node.level]: true
        }"
    >
        <div v-if="isLast" class="white-line"></div>
        <!-- <div class="tree-node__content" :style="{'padding-left': (node.level + tree.levelOffset) * tree.indent + 'px', width: rowWidth + 'px', 'font-weight': node.level == 0 ? 'bold':'normal'}" v-if="node.membersCount > 0"> -->
        <div class="tree-node__content" :style="{'padding-left': ((node.level + tree.levelOffset) * tree.indent)*2 + 'px', width: rowWidth + 'px', 'font-weight': node.level == 0 ? 'bold':'normal'}">
            <span class="tree-node__expand-icon icon-caret-right"
                @click.stop="handleExpandIconClick"
                :class="{'is-leaf': isLeaf, expand: !isLeaf && expanded}"
            ></span>
            <!-- <span style="margin-right:6px">-----</span> -->
            <!--<span-->
                <!--v-if="node.loading"-->
                <!--class="tree-node__loading icon-loading"-->
            <!--&gt;</span>-->
            <span class="tree-node__checked-pre-icon" v-if="node.checked"></span>
            <!-- <span v-if="!hideCount&&!isFlexOverFlow" class="node-content" :title="node.teamName + '(' + node.membersCount + ')'" :style="{'width': rowWidth - 100 + 'px'}">{{node.teamName + '(' + node.membersCount + ')'}}</span> -->
            <span v-if="!hideCount&&!isFlexOverFlow" class="node-content" :title="node.teamName" :style="{'max-width': rowWidth - 180 + 'px'}">{{node.teamName}}</span>
            <span v-if="!hideCount&&!isFlexOverFlow" class="node-content">({{node.membersCount}})</span>
            <span v-if="!hideCount&&isFlexOverFlow" class="flex-overflow" :title="node.teamName + '(' + node.membersCount + ')'" :style="{'width': rowWidth - 100 + 'px'}">
                <i class="node-text">{{node.teamName}}</i>
                <i class="node-count">({{node.membersCount}})</i>
            </span>
            <span v-if="hideCount" class="node-content" :title="node.teamName" :style="{'width': rowWidth - 100 + 'px'}">{{node.teamName}}</span>
            <span class="tree-node__checked-icon" v-show="hasTick&&enableCheckedClass"></span>
        </div>
        <div
            class="tree-node__children"
            v-show="expanded">
            <template v-for="(child, index) in node[this.tree.aliasChildNodes]">
                <tree-node
                    :key="index"
                    :node="child"
                    :node-list="node['childTeams']"
                    :current-index="index"
                    :row-width="rowWidth"
                    :filter-node="filterNode"
                    v-if="child.teamId != filterNode.teamId"
                    :hide-count="hideCount"
                    :hasTick="hasTick"
                    :isFlexOverFlow="isFlexOverFlow"
                ></tree-node>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TreeNode',
        props: {
            node: {
                type: Object,
                default() {
                    return {}
                }
            },
            nodeList: {
                type: Array,
                default() {
                    return []
                }
            },
            props: {},
            renderContent: Function,
            renderAfterExpand: {
                type: Boolean,
                default: true
            },
            rowWidth: {
                type: Number,
                required: true
            },
            currentIndex: {
                type: Number,
                default: 0
            },
            currentNode: {
                type: Object,
                default(){
                    return {}
                }
            },
            filterNode: {
                type: Object,
                default() {
                    return {}
                }
            },
            hideCount: Boolean,
            hasTick:{
                type: Boolean,
                default: true
            },
            isFlexOverFlow:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tree: null,
                expanded: false,
                showCheckbox: false,
                childNodeRendered: false
            }
        },
        created() {
            this.tree = this.getRoot();
        },
        mounted() {
            this.expanded = this.tree.defaultExpandAll || (this.tree.expandRootNode && this.node.level == 0);
        },
        computed: {
            isLeaf() {
                return !this.node[this.tree.aliasChildNodes] || this.node[this.tree.aliasChildNodes].length == 0;
            },
            isLast() {
                return this.currentIndex == this.nodeList.length - 1;
            },
            enableCheckedClass() {
                if(!(this.tree && this.tree.selectSingle)) {
                    return this.node.checked;
                }else {
                    return this.tree.currentNode && (this.node.teamId === this.tree.currentNode.teamId) && this.node.checked
                }
            }
        },
        watch: {
            node(val) {
                if(val.teamId == this.tree.currentNode.teamId) {
                    this.$set(val, 'checked', true);
                }
            },
            currentNode(val) {
                if(val.teamId == this.node.teamId) {
                    this.$set(this.node, 'checked', true);
                }
            }
        },
        methods: {
            getRoot() {
                let node = this.$parent;

                while(node && !node.treeRoot){
                    node = node.$parent;
                }

                return node;
            },
            handleClick() {
               if(this.tree.selectSingle) {
                   this.tree.checkedNodes.forEach((item, index) =>{
                       if(item !== this.node) {
                           this.$set(this.node, 'checked', false);
                           this.tree.checkedNodes.splice(index, 1);
                       }
                   })
               }
               if(!this.node.checked) {
                   this.tree.currentNode = this.node;
                   this.$set(this.node, 'checked', true);
                   this.handleUpdateCheckdNodes('add');
               }else {
                   this.tree.currentNode = this.tree.rootNode;
                   this.$set(this.node, 'checked', false);
                   this.handleUpdateCheckdNodes('delete');

               }
            //    console.log('当前节点',this.tree.currentNode);
            },
            handleExpandIconClick() {
                this.expanded = !this.expanded;
                let level = this.node.level;
                level = this.expanded ? level : -level;
                // console.log('level', level);
                let width = level * 20 + this.rowWidth;
                this.tree.$emit('update-tree-width', width);
                // this.tree.$parent.teamWidth = (level) * 20 + this.rowWidth;
            },
            handleUpdateCheckdNodes(type) {
                if(type === 'add') {
                    if(this.tree.selectSingle) {
                        this.tree.checkedNodes = [this.node];
                    }else {
                        this.tree.checkedNodes.push(this.node)
                    }
                }else {
                    if(this.tree.selectSingle) {
                        this.tree.checkedNodes = [];
                        // this.node = this.tree.treeNode;
                        if(this.tree.treeNode) {
                            this.$set(this.tree.treeNode, 'checked', true);
                        }
                    }else {
                        this.tree.checkedNodes.forEach((item, index) =>{
                            if(item === this.node) {
                                this.tree.checkedNodes.splice(index, 1);
                            }
                        })
                    }
                }
            }
        },
    };
</script>

// <style lang="scss" scoped>
// // 去掉最顶层的虚线，放最下面的样式才不会被上面的覆盖了
// .tree > .tree-node:before {
//     border-left: none;
// }
// .tree > .tree-node:after {
//     border-top: none;
// }
// // 把折叠图标的层级设置比竖线高
// span.tree-node__expand-icon.icon-caret-right {
//     z-index: 1000;
// }
// // 竖线
// .tree-node:before {
//     content: '';
//     width: 1px;
//     height: 100%;
//     position: absolute;
//     // left: 8px;
//     left: 27px;
//     right: auto;
//     bottom: 0px;
//     border-width: 1px;
//     border-left: 1px dashed #999999;
// }
// // 横线
// .tree-node:after {
//     content: '';
//     width: 22px;
//     position: absolute;
//     left: 30px;
//     right: auto;
//     top: 20px;
//     border-width: 1px;
//     border-top: 1px dashed #999999;
// }
// .tree-node__content span:nth-child(2) {
//     padding-left: 20px;
// }
// // //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
// // .tree-node__expand-icon.is-leaf {
// //     // color: transparent;
// //     display: none;
// // }
// // .tree-node__children {
// //     padding-left: 16px;
// // }
// .tree .icon-caret-right:after {
//     content: '';
//     width: 18px;
//     height: 20px;
//     position: absolute;
//     left: 18px;
//     // left: 38px;
//     right: auto;
//     top: 20px;
//     border-width: 1px;
//     border-top: 1px dashed #999999;
// }
// </style>

<style lang="scss" scoped>
.tree-node {
    // max-height: 600px;
    //background-color: #f8f8f8;
    background-color: transparent;
    color: #444;
    background-color: #fff;
    // border-right: 1px solid #eee;
    position: relative;

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        height: 41px;
        cursor: pointer;
        padding-left: 10px;

        &:hover {
            //background: #e0f0ec;
            background: #E7F7F3;
        }

        .tree-node__expand-icon {
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 4px;
            z-index: 2;
        }

        .icon-caret-right {
            // background: url('~@src/assets/images/icon/icon-arrow-right.png') no-repeat;
            background: url('~@src/assets/images/icon/icon-plus.png') no-repeat;
        }

        .expand {
            // background: url('~@src/assets/images/icon/icon-arrow-down.png') no-repeat;
            background: url('~@src/assets/images/icon/icon-subtract.png') no-repeat;
        }

        .is-leaf {
            //display: none;
            visibility: hidden;
        }

        .tree-node__checked-icon {
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 3px;
            margin-left: 6px;
            background: url('~@src/assets/images/icon/icon-checked.png') no-repeat;
        }

        .node-content {
            //width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .flex-overflow{
            display: flex;
            >i{
                display: inline-block;
                font-style: normal;

            }
            .node-text{
                flex: 1;
                width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.is-expand.tree-node-level-0 {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 30px;
        bottom: 20px;
        left: 7px;
        border-left: 1px dashed #999;
    }
}

// .tree-node-level-1 {
//     position: relative;

//     &::before {
//         content: '';
//         position: absolute;
//         top: 20px;
//         left: 7px;
//         width: 20px;
//         border-top: 1px dashed #999;
//         z-index: 1;
//     }

//     &::after {
//         content: '';
//         position: absolute;
//         top: 30px;
//         bottom: -20px;
//         left: 27px;
//         border-left: 1px dashed #999;
//         z-index: 1;
//     }

//     &:last-child {
//         &::after {
//             display: none;
//         }
//     }
// }

@for $i from 1 to 10 {
    .tree-node-level-#{$i} {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: ($i - 1) * 20px + 7px;
            width: 20px;
            border-top: 1px dashed #999;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            top: 30px;
            bottom: 20px;
            left: $i * 20px + 7px;
            border-left: 1px dashed #999;
            z-index: 1;
        }

        // &.is-expand::after {
        //     bottom: -20px;
        // }

        &.is-last-level::before {
            width: 30px;
        }

        &.is-last-level::after {
            top: 20px;
            // left: ($i - 1) * 20px + 7px;
            // border-left: 3px solid white;
            // z-index: 2;
        }

        .white-line {
            position: absolute;
            top: 20px;
            bottom: 0;
            left: ($i - 1) * 20px + 7px;
            border-left: 1px solid white;
            z-index: 2;
        }

        &:not(.is-indent)::before {
            // left: 10px;
        }

        &:last-child.is-last-level {
            &::after {
                display: none;
            }
        }
    }
}

.is-default {
    color: #444;

    .tree-node__content {
        &:hover {

            .is-leaf {

                &::before {
                    background: #aaa;
                }
            }
        }

        .is-leaf {

            &::before {
                background: #aaa;
            }
        }
    }
}

.is-checked {
    color: #38bc9d;

    .tree-node__content {
        &:hover {
            background: #e0f0ec;

            .is-leaf {

                &::before {
                    background: #38bc9d;
                }
            }
        }

        .is-leaf {

            &::before {
                background: #38bc9d;
            }
        }
    }
}

</style>
